// IMPORTS
@import "./Mixins";
@import "./Variables";
@import "./modules/Blocked";
@import "./modules/Form";
@import "./modules/Ticketz";
@import "./modules/Toast";
// SCROLL BAR
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  overflow: scroll;
}
*::-webkit-scrollbar-thumb {
  background: transparent;
}
body {
  overflow: hidden;
}
// CONTAINER
.appContainer {
  @include space(auto, 100vw, absolute, none, none, 0rem);
  @include flex(row, center, space-evenly);
  background: url("../assets/images/background-gradient.png");
  background-size: cover;
  transition: left 0.4s ease;
  z-index: 100;
  @include mobile {
    @include flex(column, center, center);
    overflow-y: scroll;
  }
}
// TITLE
.appTitleContainer {
  @include space(675px, auto);
  @include flex(column, flex-start, space-evenly);
  @include mobile {
    @include space(200px, 90%);
    @include flex(row, center, space-evenly, wrap);
    margin-top: 20px;
  }
}
.appTitle,
.appTitleYellow {
  @include font($white, $thirdFont, 225px);
  text-transform: uppercase;
  letter-spacing: 5px;
  user-select: none;
  margin: 0px;
  @include mobile {
    font-size: 80px;
  }
}
.appTitleYellow {
  color: $yellow;
  @include mobile {
    width: 100%;
    margin-top: 10px;
  }
}
// TEXT
.appText {
  @include font($white, $firstFont, 18px);
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
}
// CONTENT
.appContentContainer {
  @include space(auto, 50%, relative);
  @include flex(column, center, space-evenly);
  @include mobile {
    @include space(100%, 100%);
    justify-content: flex-end;
  }
}
// QUESTION
.appQuestionButton {
  @include space(auto, auto, absolute, 30px, none, none, 30px);
  @include font($white, $firstFont, 40px);
  background: none;
  z-index: 80;
  @include mobile {
    font-size: 30px;
    top: 10px;
    left: 10px;
    right: unset;
  }
}
.appQuestionVideo {
  @include space(100%, 100%, absolute);
  background: $blackFade;
  z-index: 70;
}
// CLOSE BUTTON
.appCloseButton {
  @include space(auto, auto, absolute, none, none, none, 10px);
  @include font($white, $firstFont, 60px);
  background: none;
  z-index: 100;
  @include mobile {
    top: 10px;
    font-size: 30px;
  }
}
// CSV BUTTON
.appCSVButton {
  @include space(64px, 610px, absolute, none, 10px);
  @include flex(row, center, center);
  @include font($black, $firstFont, 18px);
  @include border(2px, $black);
  background: $yellow;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 5px;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background: $white;
  }
  @include mobile {
    @include space(64px, 96%);
    margin-top: 2.5px;
  }
}
// THROBBER
.throbber,
.throbberYellow {
  @include space(20px, 20px);
  @include border(3px, $white);
  @include border(3px, transparent, bottom);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.throbberYellow {
  @include border(3px, $yellow);
  @include border(3px, transparent, bottom);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// OTHER
.lowercase {
  text-transform: lowercase;
  margin-left: 1px;
}
.overlay {
  @include space(100%, 100%, absolute, 0px, none, 0px);
  background: $blackFade;
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
