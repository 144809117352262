// IMPORTS
@import "../Mixins";
@import "../Variables";
// CONTAINER
.blockedContainer {
  @include space(171px, 610px, absolute);
  @include flex(column, center, space-evenly);
  @include border(2px, $yellow);
  background: $black;
  z-index: 50;
  @include mobile {
    @include space(171px, 95%);
    top: unset !important;
  }
}
// TITLE
.blockedTitle {
  @include space(auto, auto);
  @include font($yellow, $firstFont, 26px);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  user-select: none;
  @include mobile {
    font-size: 18px;
  }
}
// TEXT
.blockedText{
  @include font($white, $secondFont, 16px);
  margin: 0 50px;
  line-height: 20px;
}
.blockedButton {
  @include space(50px, 352px);
  @include flex(row, center, center);
  @include font($black, $firstFont, 18px);
  @include border(2px, $black);
  background: $yellow;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 5px;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background: $white;
  }
}
.greenOutline {
  -webkit-text-stroke: 1px $green;
}
.yellowOutline {
  -webkit-text-stroke: 1px $yellow;
  line-height: 60px;
  font-size: 60px;
}
.purpleOutline {
  -webkit-text-stroke: 2px $purple;
}
.orangeOutline {
  -webkit-text-stroke: 1px $orange;
}
// ICONS
.blockedMetamaskIcon {
  @include space(250px, 250px);
  background: url("../../assets/images/metamask.png");
  background-size: cover;
}
.blockedPolygonIcon {
  @include space(200px, 200px);
  background: url("../../assets/images/polygon.png");
  background-size: cover;
}
// CONNECT TO METAMASK / POLY BUTTON
.blockedConnectToMetamaskButton,
.blockedConnectToPolyButton {
  @include space(64px, 610px);
  @include flex(row, center, center);
  @include font($black, $firstFont, 18px);
  @include border(2px, $black);
  background: $orange;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 5px;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background: $white;
  }
  @include mobile {
    @include space(64px, 96%);
    margin-top: 2.5px;
  }
}
.blockedConnectToPolyButton {
  background: $purple;
}
