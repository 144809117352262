// IMPORTS
@import "../Mixins";
@import "../Variables";
// CONTAINER
.toastContainer {
  @include space(171px, 610px, absolute);
  @include flex(column, center, space-evenly);
  background: $black;
  z-index: 50;
}
// CLOSE BUTTON
.toastCloseButton{
  @include space(auto, auto, absolute, 10px, none, 10px);
  @include font($white, $firstFont, 26px);
  background: none;
}
// MESSAGE
.toastMessage {
  @include font($white, $secondFont, 18px);
  margin: 0px;
  line-height: 20px;
}
// ICON
.toastCheckIcon,
.toastTimesIcon {
  @include space(30px, 30px);
  font-size: 30px;
  color: $green;
}
.toastTimesIcon {
  color: $red;
}
