// IMPORTS
@import "../Mixins";
@import "../Variables";
// CONTAINER
.formContainer {
  @include space(auto, 620px);
  @include flex(row, center, flex-start, wrap);
  @include mobile {
    @include space(auto, auto);
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
}
// SELECT
.formSelectContainer {
  @include space(64px, 300px, relative);
  @include flex(row, center, space-between);
  margin: 5px;
  @include mobile {
    width: 400px;
    margin: 0px !important;
  }
}
.formSelectLabel {
  @include space(auto, 225px);
  @include font($white, $firstFont, 13px);
  text-align: left;
  margin: 0;
  user-select: none;
  @include mobile {
    width: 335px;
    font-size: 12px;
    text-align: center;
  }
}
.formSelect {
  @include space(40px, 70px);
  @include font($white, $firstFont, 18px);
  @include flex(row, center, center);
  @include border(2px, $white);
  background: transparent;
  outline: none;
  text-align: center;
  appearance: none;
  padding-right: 10px;
  cursor: pointer;
  &:active {
    color: $black;
  }
  @include mobile {
    @include space(30px, 60px);
    font-size: 14px;
  }
}
.formSelectArrow {
  @include space(20px, 20px, absolute, 20px, none, none, 5px);
  @include font($white, $firstFont, 20px);
  pointer-events: none;
}
// INPUT
.formInputContainer {
  @include space(100px, 300px);
  @include flex(column, center, space-evenly);
  margin: 5px;
  @include mobile {
    @include space(100px, 47%);
  }
}
.formInputLabel {
  @include font($white, $firstFont, 18px);
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}
.formInput {
  @include space(64px, 300px);
  @include font($white, $firstFont, 18px);
  @include border(2px, $white);
  background: transparent;
  text-align: center;
  outline: none;
  &::placeholder {
    @include font($gray, $firstFont, 18px);
    text-align: center;
  }
  @include mobile {
    width: 100%;
    font-size: 14px;
    padding-left: 10px;
    margin: 2.5px;
    &::placeholder {
      font-size: 14px;
    }
  }
}
// BUTTON
.formSubmitButton {
  @include space(64px, 610px);
  @include flex(row, center, center);
  @include font($white, $firstFont, 18px);
  @include border(2px, $white);
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 5px;
  cursor: pointer;
  @include mobile {
    @include space(64px, 96%);
    margin-top: 2.5px;
  }
}
