// IMPORTS
@import "../Mixins";
@import "../Variables";
// CONTAINER
.ticketzContainer {
  @include space(200px, 610px);
  @include flex(row, center, flex-start);
  overflow-y: hidden;
  overflow-x: scroll;
  // SCROLL BAR
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background: $white;
  }
  @include mobile {
    @include space(auto, 100%);
  }
}
// TICKET
.ticket,
.ticketDisabled {
  position: relative;
  @include flex(row, center, center);
  cursor: pointer;
}
.ticketDisabled {
  cursor: default;
  opacity: 0.5;
}
.ticketAmount {
  @include space(60px, 60px, absolute, 80px, none, none, 0px);
  @include flex(row, center, center);
  @include font($white, $firstFont, 16px);
  @include border(2px, $white);
  border-radius: 50%;
  user-select: none;
  @include mobile {
    @include space(50px, 50px, absolute, 50px, none, none, -10px);
    font-size: 14px;
  }
}
.ticketCheckBox {
  @include space(30px, 30px, absolute, none, 80px, none, 15px);
  @include flex(row, center, center);
  @include font($white, $firstFont, 15px);
  @include border(2px, $white);
  @include mobile{
    @include space(20px, 20px, absolute, none, 50px, none, 5px);
  }
}
