// FONT FAMILIES
@font-face {
  font-family: Pragmatica;
  src: url("/assets/fonts/PragmaticaExtended.otf") format("opentype");
}
@font-face {
  font-family: Space Grotesk;
  src: url("/assets/fonts/SpaceGrotesk.ttf") format("truetype");
}
@font-face {
  font-family: Kaneda Gothic;
  src: url("/assets/fonts/KanedaGothic.ttf") format("truetype");
}
$firstFont: "Pragmatica", sans-serif;
$secondFont: "Space Grotesk", sans-serif;
$thirdFont: "Kaneda Gothic", sans-serif;
// COLORS
$black: #0f0f0f;
$blackFade: rgba(0, 0, 0, 0.5);
$white: #ffffff;
$whiteFade: rgba(255, 255, 255, 0.7);
$gray: #9b9b9b;
$yellow: #e2ff03;
$green: #1dff00;
$red: red;
$orange: #ff8401;
$pink: #ff00b1;
$pinkDark: #a93454;
$pinkLight: rgb(203, 108, 123);
$purple: #8247e5;
$blue: #0077aa;
$darkBlue: #191b69;
$lightBlue: #02b8ff;
$backgroundGradient: linear-gradient(315deg, #130f40 0%, #000000 74%);

// BOX SHADOWS
$boxShadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.3);
$boxShadowAcitve: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
$boxShadowShoe: rgba(0, 0, 0, 0.3) 0px 19px 38px,
  rgba(0, 0, 0, 0.22) 0px 15px 12px;
// BREAK POINTS
$mobileBreakpoint: 700px;
$tabletBreakpoint: 1050px;
